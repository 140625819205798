import React from 'react'
import { Link } from 'gatsby'

import './Footer.css'


export default () => (
  <div>
    
    <br />
    <footer className="footer">
      <div className="container taCenter">
      <ul className="nav-list">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/privacy-policy/">Privacy Policy</Link></li>
          <li><Link to="/terms-of-use/">Terms of Use</Link></li>
        </ul>
        <span>
          LowCarbFoodListing.com © Copyright {new Date().getFullYear()} All rights reserved.
        </span>
      </div>
    </footer>
  </div>
)
